<template>
  <div class="user-selection">
    <c-checkout-option-form
      name="isUserUpdate"
      :value="true"
      :text="$t(`${tPath}.customertype.existingcustomer.label`)"
      :emitChangesDirectly="true"
      @optionform:change="optionFormChange"
    >
      <div class="user-search" v-if="!userSelected">
        <b-input v-model="searchQuery" :placeholder="$t(`${tPath}.registration.searchuser`)"></b-input>
        <b-row class="search-results mt-5" cols="1" cols-md="2">
          <b-col v-for="userItem in searchResults" :key="userItem.id" class="mb-5">
            <b-card class="user-result h-100 bg-secondary border-0" @click="selectUser(userItem)">
              <b-card-text>
                <template v-if="!$root.isNullOrEmpty(userItem.company)"
                  ><strong>{{ userItem.company }}</strong
                  ><br
                /></template>
                <strong>{{ userItem.firstname }} {{ userItem.lastname }}</strong
                ><br />
                <span v-if="!isPlaceholderEmail(userItem.email)" :title="userItem.email" class="text-truncate d-inline-block w-100">{{
                  userItem.email
                }}</span
                ><br />
                {{ userItem.address }}
                <template v-if="!$root.isNullOrEmpty(userItem.phone) && !isPlaceholderPhone(userItem.phone)"
                  ><br />Tel: {{ userItem.phone }}</template
                >
                <template v-if="!$root.isNullOrEmpty(userItem.phoneMobile) && !isPlaceholderPhone(userItem.phoneMobile)"
                  ><br />Mobile: {{ userItem.phoneMobile }}</template
                >
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div class="user-update" v-else>
        <b-card class="h-100 bg-secondary border-0 mb-3">
          <b-card-text>
            <template v-if="!$root.isNullOrEmpty(stepData.selectedSearchUser?.company)"><strong>{{ stepData.selectedSearchUser?.company }}</strong><br /></template>
            <strong>{{ stepData.selectedSearchUser?.firstname }} {{ stepData.selectedSearchUser?.lastname }}</strong><br />
            <span v-if="!isPlaceholderEmail(stepData.selectedSearchUser?.email)" :title="stepData.selectedSearchUser?.email" class="text-truncate d-inline-block w-100">{{ stepData.selectedSearchUser?.email }}</span>
            <br />{{ stepData.selectedSearchUser?.address }}
            <template v-if="!$root.isNullOrEmpty(stepData.selectedSearchUser?.phone) && !isPlaceholderPhone(stepData.selectedSearchUser?.phone)">
              <br />Tel: {{ stepData.selectedSearchUser?.phone }}
            </template>
            <template v-if="!$root.isNullOrEmpty(stepData.selectedSearchUser?.phoneMobile) && !isPlaceholderPhone(stepData.selectedSearchUser?.phoneMobile)">
              <br />Mobile: {{ stepData.selectedSearchUser?.phoneMobile }}
            </template>
            <div class="mt-3">
              <a href="#" class="btn btn-outline-primary" @click.prevent="resetSelectedUser" v-html="$t(`${tPath}.registration.changecustomer`)"></a>
            </div>
          </b-card-text>
        </b-card>
        <c-registration
          mode="checkout"
          :omit-login="true"
          :omit-terms="true"
          :init-values="{
            user: stepData.existingUser,
            userAddresses: [stepData.existingUserAddress]
          }"
          :translationsPath="`${tPath}.registration`"
          :emitChangesDirectly="true"
          @control:change="updateExistingUser"
        />

        <h2 class="h6 font-spacing-1">{{ $t(`${tPath}.registration.login.title`) }}</h2>
        <b-form id="UserInfoForm" class="controls-form" novalidate>
          <b-form-row class="mb-5">
            <template v-if="stepData.existingUser.userType !== 'FullUser'">
              <b-col v-bind="{ [$root.mt.key]: 12 }">
                <b-form-group class="control-checkbox" label="" label-for="CreateLoginForUser">
                  <b-form-checkbox
                    id="CreateLoginForUser"
                    name="createlogin"
                    form="UserInfoForm"
                    v-model="stepData.createUserLogin"
                    @change="updateCreateUserLogin"
                  >
                    {{ $t(`${this.tPath}.createlogin.label`) }}
                    <div class="form-text text-muted font-sm">{{ $t(`${this.tPath}.createlogin.hint`) }}</div>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </template>
            <b-col v-bind="{ [$root.mt.key]: 12 }">
              <b-form-group class="control-checkbox" label="" label-for="UserIsGastro">
                <b-form-checkbox id="UserIsGastro" name="isGastro" form="UserInfoForm" v-model="stepData.isGastro" @change="updateIsGastro">
                  {{ $t(`${this.tPath}.isgastro.label`) }}
                  <div class="form-text text-muted font-sm">{{ $t(`${this.tPath}.isgastro.hint`) }}</div>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
      </div>
    </c-checkout-option-form>
    <c-checkout-option-form
      name="isUserUpdate"
      :value="false"
      :text="$t(`${tPath}.customertype.newcustomer.label`)"
      :emitChangesDirectly="true"
      @optionform:change="optionFormChange"
    >
      <c-registration
        mode="checkout"
        :omit-login="true"
        :omit-terms="true"
        :init-values="{
          user: stepData.newUser,
          userAddresses: [stepData.newUserAddress]
        }"
        :translationsPath="`${tPath}.registration`"
        :emitChangesDirectly="true"
        @control:change="updateNewUser"
      />

      <h2 class="h6 font-spacing-1">{{ $t(`${tPath}.registration.login.title`) }}</h2>
      <b-form id="CreateLoginForm" class="controls-form" novalidate>
        <b-form-row class="mb-5">
          <b-col v-bind="{ [$root.mt.key]: 12 }">
            <b-form-group class="control-checkbox" label="" label-for="CreateLoginForUser">
              <b-form-checkbox
                id="CreateLoginForUser"
                name="createlogin"
                form="CreateLoginForm"
                v-model="stepData.createUserLogin"
                @change="updateCreateUserLogin"
              >
                {{ $t(`${this.tPath}.createlogin.label`) }}
                <div class="form-text text-muted font-sm">{{ $t(`${this.tPath}.createlogin.hint`) }}</div>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col v-bind="{ [$root.mt.key]: 12 }">
            <b-form-group class="control-checkbox" label="" label-for="UserIsGastro">
              <b-form-checkbox id="UserIsGastro" name="isGastro" form="UserInfoForm" v-model="stepData.isGastro" @change="updateIsGastro">
                {{ $t(`${this.tPath}.isgastro.label`) }}
                <div class="form-text text-muted font-sm">{{ $t(`${this.tPath}.isgastro.hint`) }}</div>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </c-checkout-option-form>
  </div>
</template>

<script>
import userSearch from '@/$plugins/services/user-search';

import { debounce } from '@/assets/js/helper/debounce';
import { unwrap } from '@/assets/js/helper/entity';

export default {
  name: 'CheckoutCustomerSelection',
  components: {},
  props: {
    configuration: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      searchQuery: '',
      searchQueryDebounced: '',
      searchResults: [],
      stepData: {
        isUserUpdate: this.configuration.isUserUpdate,
        newUser: this.configuration.newUser,
        newUserAddress: this.configuration.newUserAddress,
        existingUser: this.configuration.existingUser,
        existingUserAddress: this.configuration.existingUserAddress,
        createUserLogin: this.configuration.createUserLogin,
        isGastro: this.configuration.isGastro
      }
    }
  },
  computed: {
    userSelected() {
      return this.stepData.existingUser || this.stepData.existingUserAddress
    }
  },
  methods: {
    isPlaceholderEmail(email) {
      return email?.match(/@hunn-platzhalter-mail\.ch/gi) ?? false
    },
    isPlaceholderPhone(phone) {
      return phone?.match(/011 111 11 11/gi) ?? false
    },
    async executeSearch() {
      if (this.searchQueryDebounced.length === 0) {
        this.searchResults = []
        return
      }

      const searchResult = await userSearch.searchByQuery(this.searchQueryDebounced)
      this.searchResults = searchResult
    },
    async selectUser(user) {
      const userId = user.id
      const foundUser = await this.$store.dispatch('users/getEntity', userId)
      const userAddresses = (await this.$store.dispatch('useraddresses/getAll', { by: { name: 'user', id: userId } })) || []

      const defaultAddress = userAddresses.map((a) => unwrap(a)).find((a) => a.type === 'Default')

      this.stepData.existingUser = unwrap(foundUser)
      this.stepData.existingUserAddress = defaultAddress
      this.stepData.selectedSearchUser = user

      if (this.isPlaceholderEmail((this.stepData.existingUser || {}).email || '')) {
        this.stepData.existingUser.email = ''
      }

      if (this.isPlaceholderPhone((this.stepData.existingUser || {}).phoneMobile || '')) {
        this.stepData.existingUser.phoneMobile = ''
      }

      // Reset new user data as soon as a user is selected
      this.resetNewUser()

      // Save everything
      this.submitChanges()
    },
    updateCreateUserLogin() {
      this.submitChanges()
    },
    updateIsGastro() {
      this.submitChanges()
    },
    updateExistingUser(changedUser) {
      if (changedUser.key === 'newUser') {
        this.$set(this.stepData, 'existingUser', changedUser.value)
      }
      if (changedUser.key === 'newUserAddress') {
        this.$set(this.stepData, 'existingUserAddress', changedUser.value)
      }

      this.submitChanges()
    },
    updateNewUser(changedUser) {
      if (changedUser.key === 'newUser') {
        this.$set(this.stepData, 'newUser', changedUser.value)
      }
      if (changedUser.key === 'newUserAddress') {
        this.$set(this.stepData, 'newUserAddress', changedUser.value)
      }

      this.submitChanges()
    },
    optionFormChange(optionFormData) {
      this.stepData.isUserUpdate = optionFormData.option.value

      this.submitChanges()
    },
    resetSelectedUser() {
      this.stepData.existingUser = null
      this.stepData.existingUserAddress = null
    },
    resetNewUser() {
      this.stepData.newUser = null
      this.stepData.newUserAddress = null
    },
    submitChanges() {
      this.$store.dispatch('shoppingcart/update', this.stepData).then((result) => {
        this.stepData.existingUser = result.configuration.existingUser
        this.stepData.existingUserAddress = result.configuration.existingUserAddress
        this.stepData.newUser = result.configuration.newUser
        this.stepData.newUserAddress = result.configuration.newUserAddress
        this.stepData.selectedSearchUser = result.configuration.selectedSearchUser
      })
    }
  },
  watch: {
    searchQuery: debounce(function (newVal) {
      this.searchQueryDebounced = newVal
    }, 500),
    searchQueryDebounced: async function (val) {
      await this.executeSearch()
    }
  }
}
</script>

<style lang="scss">
.user-selection {
  .user-result {
    cursor: pointer;

    &:hover {
      border-color: $primary;
    }
  }
}
</style>
